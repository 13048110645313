import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import ContentfulPage from '../components/Page/ContentfulPage'

import withContentfulBlocks from '../components/withContentfulBlocks'
class RootIndex extends React.Component {
  render () {
    const featuredHero = get(this.props, 'data.hero.edges')
    const stories = get(this.props, 'data.stories.edges')

    return (
      <ContentfulPage
        blocks={this.props.blocks}
        footerColor='white'
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Home`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}`}
        featuredHero={featuredHero}
        stories={stories}
        showSuccessStoriesTop
      />
    )
  }
}

export default withContentfulBlocks(RootIndex)

export const pageQuery = graphql`
  query SLHomeQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "49OXjdXb42URqS667BjED7" }) {
      ...PageBlocksFragment
    }
    hero: allContentfulShelfLifePanelHero(limit:1, filter: { isActive: { eq: true } }) {
      edges { node {
       name,
       pharmacyName,
       quote{quote},
       title,
       picture {
        id,
        fluid(
          quality: 80
          maxWidth: 2800
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid_withWebp
          }
          file {
          url
          }
       }
     }
     }
   }
   stories: allContentfulShelfLifeSuccessStory{
    edges { node {
     name,
     pharmacyName,
     quote{quote},
     url,
     picture {
      id,
      fluid(
        quality: 80
        maxWidth: 2800
        resizingBehavior: FILL
      ) {
        ...GatsbyContentfulFluid_withWebp
        }
        file {
        url
        }
     }
    }
   }
 }
  }
`
